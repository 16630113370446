$(document).ready(function () {
	//Hide element when smaller than 1025

	try {
		if ($(window).width() > 1024.1) {
			fullPageInit();
		}
		aosInit();
	} catch (error) {}
	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
	filterDisplay();
	backToTop();
	bodyBanner();
	iframeRatio();
	generalFunctions();
});
const bodyWrap = document.querySelector("body");
const searchWrap = document.querySelector(".search-overlay");
const searchToggle = document.querySelector(".search-toggle");
const menuMobile = document.querySelector("header");
const hamburger = document.querySelector(".hamburger");
const counterItem = document.querySelectorAll(".counter");
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
});
Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".mobile-nav-wrap,.main-menu-toggle")) {
		menuMobile.classList.remove("open-menu");
		hamburger.classList.remove("is-active");
	}
});
function generalFunctions() {
	// Search Toggle
	searchToggle.addEventListener("click", () => {
		searchWrap.classList.toggle("open-menu");
		bodyWrap.classList.toggle("overlay-bg");
	});

	$(".drop-menu .title em").on("click", function () {
		if ($(this).parent().parent().hasClass("is-open")) {
			$(this).parent().next().slideUp();
			$(this).parent().parent().removeClass("is-open");
		} else {
			$(this).parent().next().slideDown();
			$(this).parent().parent().addClass("is-open");
		}
	});
	if (counterItem !== null) {
		if (counterItem.length) {
			const counterUp = window.counterUp.default;
			const callback = (entries) => {
				entries.forEach((entry) => {
					const el = entry.target;
					if (
						entry.isIntersecting &&
						!el.classList.contains("is-visible")
					) {
						counterUp(el, {
							duration: 2000,
							delay: 200,
						});
						el.classList.add("is-visible");
					}
				});
			};
			const IO = new IntersectionObserver(callback, { threshold: 1 });

			counterItem.forEach((counter) => {
				IO.observe(counter);
			});
		}
	}
}

function bodyBanner() {
	if ($(".working-sector-detail").length) {
		$("body").addClass("has-banner");
	}
}

function aosInit() {
	AOS.init({
		// Global settings:

		disable: window.innerWidth < 1200,
		startEvent: "load", // name of the event dispatched on the document, that AOS should initialize on
		initClassName: "aos-init", // class applied after initialization
		animatedClassName: "aos-animate", // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 20, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 0, // the delay on throttle used while scrolling the page (advanced)
		// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 120, // offset (in px) from the original trigger point
		delay: 120, // values from 0 to 3000, with step 50ms
		duration: 500, // values from 0 to 3000, with step 50ms
		easing: "ease-in-out-back", // default easing for AOS animations
		once: true,
		mirror: true, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});
}

function fullPageInit() {
	try {
		var myFullpage = new fullpage("#fullpage", {
			//Navigation
			bigSectionsDestination: null,
			menu: "#header,#menu-parallax",
			lockAnchors: false,
			fixedElements: "#header,#menu-parallax",
			anchors: [
				"firstPage",
				"secondPage",
				"thirdPage",
				"fourthPage",
				"fifthPage",
				"sixthPage",
				"seventhPage",
				"eigthPage",
				"ninePage",
				"tenthPage",
				"eleventhPage",
				"lastPage",
			],
			navigationTooltips: [
				"firstPage",
				"secondPage",
				"thirdPage",
				"fourthPage",
				"fifthPage",
				"sixthPage",
				"seventhPage",
				"eigthPage",
				"ninePage",
				"tenthPage",
				"eleventhPage",
				"lastPage",
			],
			normalScrollElements: ".scollbar-wrap,.mobile-nav-wrap",
			slidesNavigation: true,
			slidesNavPosition: "bottom",
			showActiveTooltip: true,
			//Scrolling
			css3: true,
			offsetSections: true,
			scrollingSpeed: 700,
			autoScrolling: true,
			fitToSection: false,
			paddingTop: "0",
			fitToSectionDelay: 600,
			scrollBar: false,
			easing: "easeInOutCubic",
			easingcss3: "ease",
			loopBottom: false,
			loopTop: false,
			loopHorizontal: true,
			continuousHorizontal: true,
			scrollHorizontally: true,
			continuousVertical: false,
			scrollOverflow: false,
			touchSensitivity: 15,
			normalScrollElementTouchThreshold: 5,
			//Accessibility
			keyboardScrolling: true,
			animateAnchor: true,
			recordHistory: true,
			//Design
			controlArrows: true,
			verticalCentered: true,
			resize: false,
			responsiveWidth: 0,
			responsiveHeight: 0,
			//Custom selectors
			sectionSelector: "section",
			dragAndMove: true,
			onLeave: function () {
				jQuery("section [data-aos]").removeClass("aos-animate");
				menuMobile.classList.remove("open-menu");
				hamburger.classList.remove("is-active");
			},
			onSlideLeave: function () {
				jQuery("section [data-aos]").removeClass("aos-animate");
			},
			afterSlideLoad: function () {
				jQuery("section.active [data-aos]").addClass("aos-animate");
				menuMobile.classList.remove("open-menu");
				hamburger.classList.remove("is-active");
			},
			afterLoad: function () {
				jQuery("section.active [data-aos]").addClass("aos-animate");
			},
		});
	} catch (error) {}
}

function backToTop() {
	try {
		if ($("body").hasClass("homepage")) {
			if ($(window).width() > 1200.1) {
				$(".back-to-top").on("click", function (e) {
					fullpage_api.moveTo(1);
				});
			}
			if ($(window).width() < 1200.01) {
				let btn = $(".back-to-top");
				btn.on("click", function (e) {
					e.preventDefault();
					$("html, body").animate(
						{
							scrollTop: 0,
						},
						"300"
					);
				});
			}
		} else {
			let btn = $(".back-to-top");
			btn.on("click", function (e) {
				e.preventDefault();
				$("html, body").animate(
					{
						scrollTop: 0,
					},
					"300"
				);
			});
		}
	} catch (error) {}
}

function filterDisplay() {
	$(".filter-table-wrap").each(function () {
		let x = 10;
		$(this).find("tbody tr").slice(0, 10).show();
		let size = $(this).find("tbody tr").length;
		if (size < x || size == x) {
			$(this).find(".ajax-btn-wrap").addClass("disble-btn");
		}
		$(".filter-table-wrap")
			.find(".btn-secondary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-table-wrap")
					.find("tbody tr")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).parent().addClass("disble-btn");
				}
			});
	});
}

function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$("header").toggleClass("open-menu");
		$hamburger.toggleClass("is-active");
	});
	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	let timer = null;

	primarySwiper.on("slideChangeTransitionEnd", function () {
		if (timer) {
			clearTimeout(timer); //cancel the previous timer.
			timer = null;
		}
		const currentSlide = primarySwiper.slides[primarySwiper.activeIndex];
		const currentSlideHasVideo = currentSlide.querySelector("video");
		if (currentSlideHasVideo) {
			const videoElement = currentSlide.querySelector("video");
			videoElement.play();
			videoElement.addEventListener("ended", function () {
				primarySwiper.slideToClosest();
			});
		} else {
			timer = setTimeout(() => {
				primarySwiper.slideNext();
			}, 3000);
		}
	});

	const primaryBannerVideo = document.querySelectorAll(
		".primary-banner-video"
	);
	primaryBannerVideo.forEach(function (videoElement) {
		videoElement.addEventListener("ended", function () {
			primarySwiper.slideNext();
		});
		videoElement.addEventListener("click", function (event) {
			if (this.paused) {
				this.play();
				this.parentNode.classList.remove("pause");
			} else {
				this.pause();
				this.parentNode.classList.add("pause");
			}
		});
	});
	var chiefSwiper = new Swiper(".chief-swiper .swiper", {
		// Optional parameters
		breakpointsInverse: true,
		spaceBetween: 15,
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			700: {
				slidesPerView: 2,
			},
			900: {
				slidesPerView: 3,
			},
			1400: {
				slidesPerView: 4,
				spaceBetween: 30,
			},
		},
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: true,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".chief-swiper .nav-arrow-next",
			prevEl: ".chief-swiper .nav-arrow-prev",
		},
		pagination: {
			el: ".chief-swiper  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		slidesPerView: 1,
		autoplay: {
			delay: 5000,
			pauseOnMouseEnter: true,
		},
	});
	var homePartner = new Swiper(".home-swiper-partner .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 15,
		lazy: {
			loadPrevNext: true,
		},
		loop: false,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			460: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 5,
			},

			1200: {
				slidesPerView: 6,
				grid: {
					rows: 2,
					fill: "row",
				},
				spaceBetween: 30,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".home-swiper-partner  .nav-arrow-next",
			prevEl: ".home-swiper-partner  .nav-arrow-prev",
		},
	});

	var projectThumb = new Swiper(".project-swiper-thumb  .swiper", {
		spaceBetween: 18,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			420: {
				slidesPerView: 4,
				direction: "horizontal",
			},
			768: {
				slidesPerView: 5,
				direction: "horizontal",
			},
			1024.1: {
				slidesPerView: 4,
				direction: "vertical",
			},
			1440: {
				slidesPerView: 4,
				direction: "vertical",
			},
		},
		navigation: {
			nextEl: ".project-swiper-thumb .nav-next",
			prevEl: ".project-swiper-thumb .nav-prev",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var projectMain = new Swiper(".project-swiper-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: projectThumb,
		},
	});

	$(".home-project-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("project-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-project-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-project-" + index);

		var swiper = new Swiper(".project-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: true,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				1200: {
					slidesPerView: 1.35,
				},
			},
			navigation: {
				nextEl: ".btn-next-project-" + index,
				prevEl: ".btn-prev-project-" + index,
			},
		});
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});
	$(".home-trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			lazy: {
				loadPrevNext: true,
			},
			loop: true,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 20,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

function iframeRatio() {
	if ($("body").hasClass("news-detail-page")) {
		if ($(".news-detail .fullcontent iframe").length) {
			$(".news-detail .fullcontent iframe").wrap(
				"<div class='iframe-ratio'></div>"
			);
		}
	}
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}
